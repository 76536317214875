<template>
  <v-card
    class="formulaire-fiche pa-4 overflow-y-hidden"
    :class="{ 'card-three': !detailledView }"
  >
    <v-form ref="formFiche" lazy-validation>
      <div class="formulaire-fiche--header">
        <v-btn color="red" dark @click="stopNewFiche()" v-if="detailledView">{{
          $t("actions.back")
        }}</v-btn>
        <v-row>
          <v-col cols="5">
            <v-text-field
              v-model="fiche.objet"
              :label="$t('messagerie.text.type.object')"
              :placeholder="$t('messagerie.text.type.object')"
              :rules="ValidationRules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <vue-tel-input-vuetify
              v-model="fiche.phone"
              :label="$t('messagerie.text.type.phone')"
              :placeholder="$t('messagerie.text.type.phone')"
              :rules="fiche.phone_be_contacted ? ValidationRules.required : []"
              @input="updatePhone"
              :error-messages="phoneError"
              defaultCountry="fr"
              clearable
            ></vue-tel-input-vuetify>
          </v-col>
          <v-col cols="2">
            <v-row>
              <v-checkbox
                v-model="fiche.phone_be_contacted"
                title="toto"
              ></v-checkbox>
              <v-icon>mdi-phone-incoming-outline</v-icon>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              :items="modules"
              :label="$t('messagerie.text.type.module')"
              :filter="autocompleteModule"
              v-model="fiche.module"
              item-value="id"
              return-object
              :rules="ValidationRules.required"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.name }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              :disabled="subModules.length == 0"
              :label="$t('messagerie.text.type.sous_module')"
              :items="subModules"
              :filter="autocompleteModule"
              v-model="fiche.sous_module"
              item-value="id"
              return-object
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.name }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              :items="type_demandes"
              :label="$t('messagerie.text.type.type_demande')"
              v-model="fiche.type_demande"
              item-value="id"
              return-object
              :rules="ValidationRules.required"
            >
              <template slot="selection" slot-scope="data">
                {{ $t("messagerie.parametrages." + data.item.text) }}
              </template>
              <template slot="item" slot-scope="data">
                {{ $t("messagerie.parametrages." + data.item.text) }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="fiche.url"
              :label="$t('messagerie.text.type.url')"
              :items="bases_clientsArraySelectable"
              item-value="url"
              return-object
              :rules="ValidationRules.required"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              :items="impacts"
              :label="$t('messagerie.text.type.impact')"
              v-model="fiche.impact"
              item-value="id"
              return-object
              :rules="ValidationRules.required"
            >
              <template slot="selection" slot-scope="data">
                {{ $t("messagerie.parametrages." + data.item.text) }}
              </template>
              <template slot="item" slot-scope="data">
                {{ $t("messagerie.parametrages." + data.item.text) }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              :items="ordre_priorites"
              :label="$t('messagerie.text.type.ordre_priorite')"
              v-model="fiche.ordre_priorite"
              item-value="id"
              return-object
              :rules="ValidationRules.required"
            >
              <template slot="selection" slot-scope="data">
                {{ $t("messagerie.parametrages." + data.item.text) }}
              </template>
              <template slot="item" slot-scope="data">
                {{ $t("messagerie.parametrages." + data.item.text) }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-alert
            v-for="error in errorsNewFiches"
            :key="error.input"
            type="error"
          >
            {{
              $t("messagerie.text.type." + error.input) +
                " " +
                $t("messagerie.form_error.new_fiche." + error.error)
            }}
          </v-alert>
        </v-row>
      </div>
      <div class="formulaire-fiche--footer messagerie-chat--footer--wysiwyg">
        <v-row class="align-center">
          <v-col cols="10">
            <editor
              :key="keyLangCheat"
              v-model="fiche.content"
              required
              :placeholder="$t('messagerie.text.placeholder')"
              @select-attachment="dialogPieceJointe = true"
            ></editor>
            <v-divider v-if="filesUploaded.length > 0" class="my-2"></v-divider>
            <!-- Affichage des fichiers sous l'étiteur tiptap -->
            <h5 v-if="filesUploaded.length > 0">
              {{ $t("messagerie.pj.title") }}
            </h5>
            <template v-if="filesUploaded.length <= 4">
              <chip-attachment
                v-for="file in filesUploaded"
                :key="file.new_name"
                :file="file"
                @download="downloadFile(file, true)"
                small
                class="ma-2 mb-0"
              ></chip-attachment>
            </template>
            <template v-else>
              <chip-attachment
                v-for="file in filesUploaded.slice(0, 4)"
                :key="file.new_name"
                :file="file"
                @download="downloadFile(file, true)"
                small
                class="ma-2 mb-0"
              ></chip-attachment>
              <v-chip
                v-if="filesUploaded.length > 4"
                small
                class="ma-2 mb-0"
                @click="dialogPieceJointe = true"
                >+ {{ filesUploaded.length - 4 }}
              </v-chip>
            </template>
          </v-col>
          <v-col cols="2" class="d-flex justify-center">
            <v-btn
              color="primary"
              @click="sendFiche()"
              :disabled="!isValidForm"
            >
              {{ $t("messagerie.text.send") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <dialog-attachment
      v-model="filesUploaded"
      @addFile="addFileFiche"
      @remove-file-fiche="removeFileFiche"
      :show.sync="dialogPieceJointe"
    ></dialog-attachment>
  </v-card>
</template>

<script>
import HotlineBus from "@/Components/Views/Hotline/HotlineBus";
import ValidationRules from "@/Mixins/ValidationRules";
import MessagerieService from "@/Services/MessagerieService";
import { mapGetters } from "vuex";

export default {
  name: "HotlineCreateForm",
  mixins: [ValidationRules],
  components: {
    Editor: () => import("@/Components/Commons/Inputs/Editor/Editor"),
    ChipAttachment: () =>
      import("@/Components/Commons/Inputs/Attachment/ChipAttachment"),
    DialogAttachment: () =>
      import("@/Components/Commons/Inputs/Attachment/DialogAttachment"),
  },
  props: {
    keyLangCheat: String,
    impacts: Array,
    modules: Array,
    type_demandes: Array,
    ordre_priorites: Array,
    bases_clientsArraySelectable: Array,
    detailledView: Boolean,
    editing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fiche: {
      deep: true,
      handler() {
        this.$emit("input", this.fiche);
      },
    },
  },
  computed: {
    ...mapGetters(["getBase"]),
    /**
     * retourne les sous module du module
     */
    subModules() {
      if (this.modules) {
        if (this.fiche.module != null) {
          let subModules = this.fiche.module
            ? this.modules.find((mod) => mod.id == this.fiche.module.id)
                .subModules
            : [];
          return subModules.sort(this.compareString);
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    isValidForm() {
      return (this.fiche.content == "<p></p>" ? false : true) && (!!this.fiche.content)
    },
  },
  data() {
    return {
      phoneError: [],
      errorsNewFiches: [],
      errorMessages: "",
      fiche: {},
      filesUploaded: [],
      dialogPieceJointe: false,
    };
  },
  methods: {
    resetFiche() {
      this.fiche = {
        objet: "",
        phone: "",
        module: null,
        sous_module: null,
        type_demande: null,
        impact: null,
        ordre_priorite: null,
        content: "",
      }
    },
    removeFileFiche(fileRemoved){
      this.fiche.files = this.fiche.files.filter(f => f.new_name != fileRemoved.new_name)
    },
    updatePhone(number, phone) {
      if (phone.isValid) {
        this.phoneError = [];
        this.fiche.phoneCountry = phone.regionCode;
      } else {
        this.phoneError = [this.$t("validation.phoneInvalid")];
      }
    },
    //Permet de trier l'autocomplete des modules par le nom
    autocompleteModule(item, query, itemtext) {
      return item.name.toLowerCase().includes(query.toLowerCase());
    },
    openDialogPieceJointe() {
      HotlineBus.$emit("FormFiche-open-dialog-piece-jointe");
    },
    /**
     * Tri tableau sur name
     */
    compareString(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    sendFiche() {
      if (this.$refs.formFiche.validate() && this.phoneError == 0) {
        this.$emit("send", this.fiche);
        this.resetFiche()
      }
    },
    stopNewFiche() {
      HotlineBus.$emit("FormFiche-stop-new-fiche", this.fiche);
    },
    addFileFiche(fileAdded) {
      if (typeof this.fiche.files == "undefined") {
        this.fiche.files = [];
      }
      this.fiche.files.push(fileAdded);
    },
    /**
     * Permet de download un fichier lors du clic sur sa card
     * @var file le nom du fichier
     */
    downloadFile(file, preload = false) {
      let object = null;
      if (preload) {
        object = file;
      } else {
        object = this.selectedFiche.fiche.files.find((f) => f.new_name == file);
      }

      MessagerieService.downloadFile({
        preload,
        file: object,
        fiche: this.selectedFiche.fiche.id,
      }).then((res) => {
        let fileURL = window.URL.createObjectURL(
          new Blob([res], { type: object.type })
        );
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", object.name);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
  mounted() {
    this.fiche.url = this.bases_clientsArraySelectable.find(client => {
      return client.text == this.getDns()
    })
  }
};
</script>

<style lang="scss">
.messagerie-chat--footer--wysiwyg {
  display: grid;
  grid-template-columns: 5fr 1fr;

  //noinspection CssUnusedSymbol
  .tiptap-vuetify-editor__content {
    max-height: 250px;

    img {
      display: block;
      max-width: 800px;
      max-height: 400px;
      width: auto;
      height: auto;
    }
  }
}
</style>
